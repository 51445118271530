<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs
      :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'ITem - Dimension' }]"
    />
    <div v-if="loading" class="text-xs-center" style="padding: 15px">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-card v-else style="border-radius: 10px 10px 0 0">
      <v-toolbar card style="border-radius: 10px 10px 0 0" color="primary" dark>
        <v-toolbar-title>{{ nombre }} - {{ itemNombre }} </v-toolbar-title>
      </v-toolbar>
      <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditItem', params: { id: $route.params.id } }" flat>
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListaPrecios', params: { id: $route.params.id } }" flat>
          <span>Precios</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ItemDimension', params: { id: $route.params.id } }" flat color="teal">
          <span>Peso</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListItemImagen', params: { id: $route.params.id } }" flat>
          <span>Imagenes</span>
          <v-icon>description</v-icon>
        </v-btn>
      </v-bottom-nav>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Dimensiones</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-form ref="form" v-model="validForm" lazy-validation @submit.prevent="submitDimension">
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-flex md12 sm12 xs12>
              <v-text-field
                v-model="form.peso"
                :disabled="processingForm"
                label="Peso (kgs)"
                outline
                :rules="rules.peso"
                :error="!!formErrors.peso"
                :error-messages="formErrors.peso"
                @keyup="
                  () => {
                    formErrors.peso = undefined;
                    delete formErrors.peso;
                  }
                "
              />
            </v-flex>
            <v-flex md4 sm12 xs12>
              <v-text-field
                v-model="form.alto"
                :disabled="processingForm"
                label="Alto (cms)"
                outline
                :rules="rules.alto"
                :error="!!formErrors.alto"
                :error-messages="formErrors.alto"
                @change="calVol()"
                @keyup="
                  () => {
                    formErrors.alto = undefined;
                    delete formErrors.alto;
                  }
                "
              />
            </v-flex>
            <v-flex md4 sm12 xs12>
              <v-text-field
                v-model="form.ancho"
                :disabled="processingForm"
                label="Ancho (cms)"
                outline
                :rules="rules.ancho"
                :error="!!formErrors.ancho"
                :error-messages="formErrors.ancho"
                @change="calVol()"
                @keyup="
                  () => {
                    formErrors.ancho = undefined;
                    delete formErrors.ancho;
                  }
                "
              />
            </v-flex>
            <v-flex md4 sm12 xs12>
              <v-text-field
                v-model="form.largo"
                :disabled="processingForm"
                label="Largo (cms)"
                outline
                :rules="rules.largo"
                :error="!!formErrors.largo"
                :error-messages="formErrors.largo"
                @change="calVol()"
                @keyup="
                  () => {
                    formErrors.largo = undefined;
                    delete formErrors.largo;
                  }
                "
              />
            </v-flex>
            <v-flex md6 sm12 xs12>
              <v-text-field
                v-model="form.volumen_cm3"
                :disabled="processingForm"
                label="Volumen Cm3"
                outline
                readonly
                :rules="rules.volumen_cm3"
                :error="!!formErrors.volumen_cm3"
                :error-messages="formErrors.volumen_cm3"
                @keyup="
                  () => {
                    formErrors.volumen_cm3 = undefined;
                    delete formErrors.volumen_cm3;
                  }
                "
              />
            </v-flex>
            <v-flex md6 sm12 xs12>
              <v-text-field
                v-model="form.volumen_m3"
                :disabled="processingForm"
                label="Volumen M3"
                outline
                readonly
                :rules="rules.volumen_m3"
                :error="!!formErrors.volumen_m3"
                :error-messages="formErrors.volumen_m3"
                @keyup="
                  () => {
                    formErrors.volumen_m3 = undefined;
                    delete formErrors.volumen_m3;
                  }
                "
              />
            </v-flex>
            <!-- <v-flex md12 sm12 xs12>
              <v-autocomplete
                v-model="form.estado"
                :items="estados"
                dense
                outline
                clearable
                small-chips
                label="Seleccionar Estado"
                item-text="nombre"
                item-value="id"
                :error="!!formErrors.estado"
                :error-messages="formErrors.estado"
                @change="
                  () => {
                    formErrors.estado = undefined;
                    delete formErrors.estado;
                  }
                "
              />
            </v-flex>-->
          </v-layout>
          <v-divider></v-divider>
          <center style="padding:15px 0 0;">
            <v-btn
              type="submit"
              color="success"
              :disabled="!validForm || processingForm"
              :loading="processingForm"
              >Guardar</v-btn
            >
            <v-btn
              color="error"
              @click="$router.push({ name: 'ListadoProductItems', params: { id: id } })"
            >
              Volver
            </v-btn>
          </center>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Dimensiones" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
  },
  data() {
    return {
      formErrors: {},
      nombre: "",
      itemNombre: "",
      categoria: "",
      loading: false,
      form: {
        peso: "",
        ancho: 0,
        largo: 0,
        alto: 0,
        volumen_cm3: "",
        volumen_m3: "",
        estado: true,
      },
      id: "",
      estados: [
        { id: false, nombre: "inactivo" },
        { id: true, nombre: "activo" },
      ],
      validForm: true,
      processingForm: false,
      rules: {
        tipo: [(v) => !!v || "El nombre es requerido"],
      },
    };
  },
  computed: {
    ...mapState({
      currentProducto: (state) => state.productos.currentProducto,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
  },
  async created() {
    this.loading = true;
    await this.getDimension({ itemId: this.$route.params.id }).then((response) => {
      const itemInfo = response.data.data;
      this.setForm(itemInfo);
    });
    await this.getItem({ itemId: this.$route.params.id }).then((response) => {
      const itemInfo = response.data.data;
      this.itemNombre = itemInfo.nombre;
      this.id = itemInfo.producto_id;
    });
    await this.getProducto({ productoId: this.id }).then((response) => {
      const productoInfo = response.data.data;
      this.nombre = productoInfo.nombre;
      this.categoria = productoInfo.categoria.nombre;
    });
    this.loading = false;
  },
  methods: {
    ...mapActions({
      updateDimension: "items/updateDimension",
      getDimension: "items/getDimension",
      getItem: "items/getItem",
      getProducto: "productos/getProducto",
    }),
    calVol() {
      this.form.volumen_cm3 = this.form.largo * this.form.alto * this.form.ancho;
      this.form.volumen_m3 = this.form.volumen_cm3 / 1000000;
    },
    setForm(item) {
      this.form.peso = item.peso;
      this.form.ancho = item.ancho;
      this.form.largo = item.largo;
      this.form.alto = item.alto;
      this.form.volumen_cm3 = item.volumen_cm3;
      this.form.volumen_m3 = item.volumen_m3;
    },
    submitDimension() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.updateDimension({ itemId: this.$route.params.id, data: this.form })
        .then((response) => {
          this.processingForm = false;
        })
        .catch((error) => {
          this.processingForm = false;
        });
    },
  },
};
</script>
